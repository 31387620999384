<script>
import { Bar, mixins } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'

export default {
  components: {
    hexToRgba,
    CustomTooltips
  },
  extends: Bar,
  mixins: [mixins.reactiveData],
  data() {
    return {
      chartData: '',
      range: '',
      errors: {
        code: '',
        status: '',
        headers: ''
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData)
  },
  created () {
    this.$http.get(`transaction-age`,{
      transaction_daterange: this.rage
    })
    .then((response) => {
      const responseData = response.data.buying;
      this.chartData = {
        labels: ['17 - 20', '21 - 25', '26 - 30', '31 - 35', '36 - 40', '41 - 45', '45 - 50', '50 - 100'],
        datasets: [
          {
            label: 'Ages',
            backgroundColor: ['#0d91e3','#0d26e3', '#19c29d', '#763ecd', '#cd0de3', '#e30d91', '#f87979', '#00d9e0'],
            data: [responseData.first, responseData.second, responseData.third, responseData.fourth, responseData.fifth, responseData.sixth, responseData.seventh, responseData.eight]
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        }
      }
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
}
</script>
