    <template>
    <div class="animated fadeIn">
        <b-row>
            <b-col>
              <b-card no-body class="bg-primary">
                <b-card-body class="pb-0">
                  <h4 class="mb-0" v-if="this.resultTransactionTarget.percent == null">{{ this.default.percent }}</h4>
                <h4 class="mb-0" v-else>{{ this.resultTransactionTarget.percent }}</h4>
                  <p v-if="this.resultTransactionTarget.percent == null">Buying Transaction {{ this.default.percent }} from Target</p>
                <p v-else>Buying Transaction {{ this.default.percent }} from Target</p>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col>
              <b-card no-body class="bg-primary">
                <b-card-body class="pb-0">
                    <h4 class="mb-0" v-if="this.resultTransactionTarget.resultSelling == null">{{ this.default.resultSelling }}</h4>
                    <h4 class="mb-0" v-else>{{ this.resultTransactionTarget.resultSelling }}</h4>
                  <p>Selling Transaction</p>
                </b-card-body>
              </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card header="Transaction Diagrams">
                    <form @submit.prevent="transactionPost">
                    <b-row>
                        <b-col sm="8">
                            <h5>Transaction Date</h5>
                            <b-form-group>
                                <b-input-group>
                                    <b-input-group-prepend is-text>
                                        <i class="fa fa-calendar"></i>
                                    </b-input-group-prepend>
                                    <range-picker :start="startDate" :end="endDate" @picker="transactionChange"></range-picker>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <h5>Transaction Buying Target</h5>
                            <b-form-group>
                                <b-input-group>
                                    <b-form-input type="number" v-model="resultTransactionTarget.target" placeholder="100"></b-form-input>
                                    <!-- Attach Right button -->
                                    <b-input-group-append>
                                    <b-button type="submit" variant="primary">Submit</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    </form>
                <br>
                <br>
                <b-row>
                    <b-col sm="12" lg="6">
                    <b-row>
                        <b-col sm="12">
                        <h4 style="text-align:center">Buying Transaction Based On Gender</h4>
                        </b-col>
                    </b-row>
                    <Line-Buying chartId="chart-buying-01" ref="buyTransaction"/>
                    </b-col>
                    <b-col sm="12" lg="6">
                    <b-row>
                        <b-col sm="12">
                        <h4 style="text-align:center">Selling Transaction Based On Gender</h4>
                        </b-col>
                    </b-row>
                    <Line-Selling chartId="chart-selling-01" ref="sellTransaction"/>
                    </b-col>
                </b-row>
                <br>
                <br>
                    <b-row>
                    <b-col sm="12" lg="6">
                    <b-row>
                        <b-col sm="12">
                        <h4 style="text-align:center">Buying Transaction Based On Age</h4>
                        </b-col>
                    </b-row>
                    <Line-Transaction-Buy chartId="chart-buying-01"/>
                    </b-col>
                    <b-col sm="12" lg="6">
                    <b-row>
                        <b-col sm="12">
                        <h4 style="text-align:center">Selling Transaction Based On Age</h4>
                        </b-col>
                    </b-row>
                    <Line-Transaction-Sell chartId="chart-selling-01"/>
                    </b-col>
                </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
    </template>

    <script>
    import LineSelling from './charts/LineSelling'
    import LineBuying from './charts/LineBuying'
    import LineTransactionBuy from './charts/LineTransactionBuy'
    import LineTransactionSell from './charts/LineTransactionSell'
    // import BarTransactionLocation from './charts/BarTransactionLocation'

    let transStart = '';
    let transEnd = '';
    export default {
    name: 'dashboard-transaction',
    components: {
        LineSelling,
        LineBuying,
        LineTransactionBuy,
        LineTransactionSell,
    },
    data: function () {
        return {
        // Dismiss alert script
            totalTransaction: '',
            activeTransaction: '',
            newTransaction: '',
            TransactionTarget: '',
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            selected: 'Month',
            startDate: '',
            endDate: '',
            resultTransactionTarget: {
                target: '',
                percent: null,
                resultSelling: null
            },
            default: {
                percent: null,
                resultSelling: null
            },
            errors: {
                code: '',
                status: '',
                headers: ''
            }
        }
    },
    created () {
        this.$http.post(`show-target-transaction`)
        .then((response) => {
            this.default.percent = response.data.percentBuying;
            this.default.resultSelling = response.data.resultSell;
        })
        .catch((error) => {
            if (error.response) {
                this.errors.code = error.response.status;
                this.errors.status = error.response.data.meta.code;
                this.errors.headers = error.response.headers;
            }
        })
    },
    methods: {
        // Dissmiss Alert Script
        transactionChange(value) {
            this.startDate = value.startDate;
            this.endDate = value.endDate;
        },
        transactionPost() {
            if (this.startDate != '' && this.endDate != '') {
                transStart = this.startDate;
                transEnd = this.endDate;
            }else{
                transEnd = this.$setDate.format('YYYY-MM-DD');
                transStart = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
            }

            this.$http.post(`show-target-transaction`,{
                start_from: transStart,
                end_to: transEnd,
                target: this.resultTransactionTarget.target
            }).then((response) => {
                this.resultTransactionTarget.percent = response.data.percentBuying;
                this.resultTransactionTarget.resultSelling = response.data.resultSell;
            }).catch((error) => {
                if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.status = error.response.data.meta.code;
                    this.errors.headers = error.response.headers;
                }
            })
            this.$refs.buyTransaction.postTransaction(this.startDate, this.endDate, this.resultTransactionTarget.target);
            this.$refs.sellTransaction.postTransaction(this.startDate, this.endDate, this.resultTransactionTarget.target);
        },
        countDownChanged (dismissCountDown) {
        this.dismissCountDown = dismissCountDown
        },
        showAlert () {
        this.dismissCountDown = this.dismissSecs
        },

        variant (value) {
        let $variant
        if (value <= 25) {
            $variant = 'info'
        } else if (value > 25 && value <= 50) {
            $variant = 'success'
        } else if (value > 50 && value <= 75) {
            $variant = 'warning'
        } else if (value > 75 && value <= 100) {
            $variant = 'danger'
        }
        return $variant
        },
        flag (value) {
        return 'flag-icon flag-icon-' + value
        }
    }
    }
    </script>

    <style>
    /* IE fix */
    #card-chart-01, #card-chart-02 {
        width: 100% !important;
    }
    </style>
